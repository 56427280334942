<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="名称" prop="title">
        <el-input v-model="info.title" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="Logo" prop="logo">
        <SelectImgs :selectNum="1" :selectData="[info.logo]" :selectFunc="imgChoose" :idx="1"></SelectImgs>
        <span>216*216px</span>
      </el-form-item>
      <el-form-item label="图片" prop="img">
        <SelectImgs :selectNum="1" :selectData="[info.img]" :selectFunc="imgChoose" :idx="2"></SelectImgs>
        <span>660*400px</span>
      </el-form-item>
      <el-form-item label="城市">
        <el-input v-model="info.city" placeholder="请输入城市"></el-input>
      </el-form-item>
      <el-form-item label="活动时间">
        <el-date-picker
            v-model="info.start_date"
            type="date"
            placeholder="开始日期">
        </el-date-picker> ~
        <el-date-picker
            v-model="info.end_date"
            type="date"
            placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="描述">
        <el-input type="textarea" rows="5" v-model="info.intro" placeholder="请输入描述"></el-input>
      </el-form-item>
      <el-form-item label="内容">
        <wang-edit :txt="info.content" :callback="setText"></wang-edit>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
import WangEdit from "@/components/WangEdit";
export default {
  data() {
    return {
      tableHeader: [],
      tableData: [],
      info: {
        id: 0,
        img: '',
        logo: '',
        intro: '',
        title: '',
        city: '',
        start_date: '',
        end_date: '',
        content: ''
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ]
      },
    };
  },
  created() {
    if (this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
  },
  components: {
    SelectImgs,
    WangEdit
  },
  computed: {},
  methods: {
    setText(txt) {
      this.info.content = txt
    },
    imgChoose(item,idx) {
      if(idx==1) {
        this.info.logo = item.pic
      } else {
        this.info.img = item.pic
      }
    },
    getInfo() {
      var that = this
      this.$api.activity.activityDetail({id: that.info.id}, function (res) {
        if (res.errcode == 0) {
          that.info = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = this.info
          this.$api.activity.activityEdit(param, function (res) {
            if (res.errcode == 0) {
              that.success(res.errmsg)
              that.reBack()
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  min-height: 500px;
}
</style>
